import PropTypes from 'prop-types';
import {useState, useRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import Slider from 'react-slick';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {useTheme, styled} from '@mui/material/styles';
import {Box, Typography, Stack, MenuItem, IconButton, Tooltip, ListItemIcon} from '@mui/material';
// utils
import {fCFA, fNumberSpace} from '../../../../../utils/formatNumber';
//
import {CarouselControlsPaging1} from '../../../../../shared/components/carousel';
import {MenuPopover} from '../../../../../shared/components';
import {useSubscription} from "../../../../subscriptions/subscriptions/services/subscription.gql";
import {useAccountStore} from "../../services/account.store";
import {CompareArrows, Search} from "@mui/icons-material";

// ----------------------------------------------------------------------

const HEIGHT = 276;

const RootStyle = styled('div')(({theme}) => ({
    position: 'relative',
    height: HEIGHT,
    '& .slick-list': {
        borderRadius: theme.shape.borderRadiusMd
    }
}));

const CardItemStyle = styled('div')(({theme}) => ({
    position: 'relative',
    height: HEIGHT - 16,
    backgroundSize: 'cover',
    padding: theme.spacing(3),
    backgroundRepeat: 'no-repeat',
    color: theme.palette.common.white,
    backgroundImage: 'url("/static/bg_card.png")',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}));

const shadowStyle = {
    mx: 'auto',
    width: 'calc(100% - 16px)',
    borderRadius: 2,
    position: 'absolute',
    height: HEIGHT - 50,
    zIndex: 8,
    bottom: 8,
    left: 0,
    right: 0,
    bgcolor: 'grey.500',
    opacity: 0.32
};

// ----------------------------------------------------------------------

function MoreMenuButton() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton ref={anchorRef} size="large" color="inherit" sx={{opacity: 0.48}} onClick={handleOpen}>
                <Icon icon={moreVerticalFill} width={20} height={20}/>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{p: 1, mt: -1, width: 'auto'}}
            >
                <MenuItem
                    component={RouterLink}
                    to='/app/account-operations'
                    // onClick={handleClose}
                    sx={{py: 0.75, px: 1.5, borderRadius: 0.75}}
                >
                    <Search/>

                    <Typography variant="body2">Recherche operations</Typography>
                </MenuItem>
                {/*<MenuItem*/}
                {/*    component={RouterLink}*/}
                {/*    to='#'*/}
                {/*    // onClick={handleClose}*/}
                {/*    sx={{py: 0.75, px: 1.5, borderRadius: 0.75}}*/}
                {/*>*/}
                {/*    <CompareArrows/>*/}

                {/*    <Typography variant="body2">Demande de transfert</Typography>*/}
                {/*</MenuItem>*/}

            </MenuPopover>
        </>
    );
}

CardItem.propTypes = {
    card: PropTypes.shape({
        balance: PropTypes.number,
        cardHolder: PropTypes.string,
        cardNumber: PropTypes.string,
        cardType: PropTypes.string,
        cardValid: PropTypes.string
    })
};

function CardItem({customer, account}) {
    const {availableBalance, compte, operationDate} = account;
    const [showCurrency, setShowCurrency] = useState(true);

    const onToggleShowCurrency = () => {
        setShowCurrency((prev) => !prev);
    };

    return (
        <CardItemStyle>
            <Box sx={{position: 'absolute', top: 16, right: 16, zIndex: 9}}>
                <MoreMenuButton/>
            </Box>

            <div>
                <Typography variant='subtitle2' sx={{mb: 2, opacity: 0.72}}>Solde actuel</Typography>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='h3'>
                        {showCurrency ? fCFA(availableBalance) : '********'}
                    </Typography>
                    <Tooltip title={showCurrency ? 'Cacher' : 'Afficher'}>
                        <IconButton color='inherit' onClick={onToggleShowCurrency} sx={{opacity: 0.48}}>
                            <Icon icon={showCurrency ? eyeOffFill : eyeFill}/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </div>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                <Box component='img' src={`/static/icons/ic_bci.svg`} sx={{height: 32}}/>
                <Typography variant='h4' sx={{typography: 'subtitle1', textAlign: 'right'}}>{fNumberSpace(compte)}</Typography>
            </Stack>

            <Stack direction="row" spacing={5}>
                <div>
                    <Typography sx={{mb: 1, typography: 'caption', opacity: 0.48}}>Nom Client</Typography>
                    <Typography sx={{typography: 'subtitle1'}}>{customer?.name ?? ''}</Typography>
                </div>
                <div>
                    <Typography sx={{mb: 1, typography: 'caption', opacity: 0.48}}>Date Solde</Typography>
                    <Typography sx={{typography: 'subtitle1'}}>{operationDate}</Typography>
                </div>
            </Stack>
        </CardItemStyle>
    );
}

export default function AccountInfoCard() {
    const theme = useTheme();

    const {customer, accounts} = useSubscription();
    const {setSelectedAccount, selectedAccountIndex} = useAccountStore();

    const settings = {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: selectedAccountIndex,
        rtl: Boolean(theme.direction === 'rtl'),
        ...CarouselControlsPaging1({
            color: 'primary.main',
            bottom: '16px !important',
            right: '16px !important'
        }),
        afterChange: (index) => {
            const selectedAccount = accounts[index];
            setSelectedAccount(selectedAccount, index);
        }
    };

    return (
        <RootStyle>
            <Box sx={{position: 'relative', zIndex: 9}}>
                <Slider {...settings}>
                    {accounts.map((account) => (
                        <CardItem key={account.id} {...{customer, account}}/>
                    ))}
                </Slider>
            </Box>

            <Box sx={{...shadowStyle}}/>
            <Box sx={{...shadowStyle, opacity: 0.16, bottom: 0, zIndex: 7, width: 'calc(100% - 40px)'}}/>
        </RootStyle>
    );
}
