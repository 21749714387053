import React from 'react';
import {AccountsDashboardPage} from '../features/accounts/accounts';

export default function HomePage() {

    return (
        <AccountsDashboardPage/>
    );
}

