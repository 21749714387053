import React, {lazy} from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
import MainLayout from './home/MainLayout';
import Loadable from './shared/components/Loadable';
import {NotFound, Page500} from './shared/pages';
import {LogoOnlyLayout} from './home';
import AppLayout from './home/AppLayout';

const HomePage = Loadable(lazy(() => import('./home/HomePage')));
const AccountOperationsSearchPage = Loadable(lazy(() => import('./features/accounts/accounts/pages/AccountOperationsSearchPage')));
const NotificationsPage = Loadable(lazy(() => import('./features/notifications/notifications/pages/NotificationsPage')));
const NotificationPage = Loadable(lazy(() => import('./features/notifications/notifications/pages/NotificationPage')));
const SubscriptionBeneficiaryPage = Loadable(lazy(() => import('./features/subscriptions/subscription_beneficiaries/pages/SubscriptionBeneficiaryPage')));

const AppRouter = () => {

    return useRoutes([
        {
            path: '/',
            element: <MainLayout/>,
            children: [
                {
                    path: '/', element: <Navigate to={'/app'} replace/>
                },
            ]
        },

        {
            path: '/app',
            element: <AppLayout/>,
            children: [
                {path: '/app', element: <Navigate to={'/app/home'} replace/>},
                {path: 'home', element: <HomePage/>},

                {
                    path: 'account-operations', element: <AccountOperationsSearchPage/>
                },
                {
                    path: 'notifications', element: <NotificationsPage/>
                },
                {
                    path: 'notifications/:id', element: <NotificationPage/>
                },
                {
                    path: 'subscription-beneficiaries', element: <SubscriptionBeneficiaryPage/>
                },
            ]
        },

        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                {path: '500', element: <Page500/>},
                {path: '404', element: <NotFound/>},
                {path: '*', element: <Navigate to={'/404'} replace/>}
            ]
        },

        {path: '*', element: <Navigate to={'/404'} replace/>}
    ]);
};

export default AppRouter;
