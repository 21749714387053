import React from 'react';

import {Grid} from '@mui/material';

import {
    AccountBalanceAccountingWidget,
    AccountInfoCard,
    AccountOperationChartWidget,
    AccountOperationsPieWidget,
    LastAccountOperationsWidgets,
    ValuePositionWidget,
} from './widgets';


export default function AccountDashboard() {

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={7}>
                <AccountInfoCard/>
            </Grid>

            <Grid container spacing={3} item xs={12} md={5}>
                <Grid item xs={12} sm={6} md={12}>
                    <AccountBalanceAccountingWidget/>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                    <ValuePositionWidget/>
                </Grid>
            </Grid>


            <Grid item xs={12} md={7}>
                <AccountOperationChartWidget/>
            </Grid>

            <Grid item xs={12} md={5}>
                <AccountOperationsPieWidget/>
            </Grid>

            <Grid item xs={12}>
                <LastAccountOperationsWidgets/>
            </Grid>

        </Grid>
    );
}
