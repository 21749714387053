import {createContext} from 'react';

import axios from 'axios'
import appConfig from '../configs';
import useAuth from '../hooks/useAuth';
import PropTypes from 'prop-types';

export const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

const _axios = axios.create();

const configure = (auth) => {
    if (!auth.isAuthenticated) return;

    _axios.interceptors.request.use(async (config) => {
        try {
            await auth.updateToken(5);

            const token = auth.user.getToken();

            config.headers.Authorization = `Bearer ${token}`;

            return config;

        } catch (e) {
            auth.login();
        }
    });
};


const fullApiV1Url = (operation) => `${appConfig.httpBaseUrl}/api/v1/${operation}`;

const _url = (endpoint) => (operation) => `${endpoint}/${operation}`;

const callHttp = async (httpAsyncFn) => {
    if (!httpAsyncFn) throw 'Aucune operation a executer';

    try {
        return  httpAsyncFn();
    } catch (error) {
        let message;
        if (error.response) {
            message = error.response.data;
        } else if (error.request) {
            message = error.request;
        } else if (error.message) {
            message = error.message;
        } else {
            message = error;
        }

        // console.log({error});
        // console.log({message});

        throw message;
    }
}

const callHttpForData = async (httpAsyncFn) => {
    const {data} = await callHttp(httpAsyncFn);

    return data;
}

const http = (_axios, urlBuilder) => {
    return {
        http: _axios,
        get: (operation, data) => {
            const endpoint = urlBuilder(operation);
            // console.log({endpoint});

            return callHttp(() => _axios.get(endpoint, data));
        },
        getData: (operation, data) => {
            const endpoint = urlBuilder(operation);
            // console.log({endpoint});

            return callHttpForData(() => _axios.get(endpoint, data));
        },
        post: (operation, data) => {
            const endpoint = urlBuilder(operation);
            // console.log({endpoint});

            return callHttp(() => _axios.post(endpoint, data));
        },
        put: (operation, data) => {
            const endpoint = urlBuilder(operation);
            // console.log({endpoint});

            return callHttp(() => _axios.put(endpoint, data));
        },
        destroy: (operation) => {
            const endpoint = urlBuilder(operation);
            // console.log({endpoint});

            return callHttp(() => _axios.delete(endpoint));
        },
    }
}

const create = (http, endpoint) => (entity) => {
    return callHttpForData(() => http.post(endpoint, entity));
}

const update = (http, endpoint) => (id, entity) => {
    console.log('###@@@ Update: ', {id, entity});
    endpoint = _url(endpoint)(id);
    return callHttpForData(() => http.put(endpoint, entity));
}

const destroy = (http, endpoint) => (id) => {
    endpoint = _url(endpoint)(id);
    return callHttpForData(() => http.destroy(endpoint));
}

const findById = (http, endpoint) => (id) => {
    endpoint = _url(endpoint)(id);
    return callHttpForData(() => http.get(endpoint));
}

const findAll = (http, endpoint) => (page = 0, size = 20) => {
    endpoint = _url(endpoint)(`?page=${page}&size=${size}`);
    return callHttpForData(() => http.get(endpoint));
}

const crudPartial = http => {
    return {
        create: endpoint => create(http, endpoint),
        update: endpoint => update(http, endpoint),
        destroy: endpoint => destroy(http, endpoint),
        findById: endpoint => findById(http, endpoint),
        findAll: endpoint => findAll(http, endpoint),
    }
}

const crud = crudPartial => endpoint => {
    const {create, update, destroy, findById, findAll} = crudPartial;
    return {
        http,
        endpoint,
        url: _url(endpoint),
        create: create(endpoint),
        update: update(endpoint),
        destroy: destroy(endpoint),
        findById: findById(endpoint),
        findAll: findAll(endpoint),
    }
}

const HttpContext = createContext({apiHttp: {}});

HttpProvider.propTypes = {
    children: PropTypes.node
};

function HttpProvider({children}) {

    const auth = useAuth();

    let apiHttp = http(_axios, fullApiV1Url);

    if (auth.isAuthenticated) {
        configure(auth);

        apiHttp = http(_axios, fullApiV1Url);
        const _crudPartial = crudPartial(apiHttp);
        const _crud = crud(_crudPartial);
        const {findById, findAll, create, update, destroy} = _crudPartial;

        apiHttp = {...apiHttp, findById, findAll, create, update, destroy, crud: _crud};
    }

    apiHttp = {...apiHttp, callHttp};

    return (
        <HttpContext.Provider value={{apiHttp}}>
            {children}
        </HttpContext.Provider>
    )
}


export {HttpContext, HttpProvider};
