import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// material
import {useTheme, alpha} from '@mui/material/styles';
import {AccountCircle} from '@mui/icons-material';
import {Box, Link, Drawer, Typography, Avatar, Stack, Tooltip, CardActionArea} from '@mui/material';
// hooks
import useCollapseDrawer from '../../shared/hooks/useCollapseDrawer';
// components
import NavSection from './NavSection';
import {Logo, Scrollbar, MHidden} from '../../shared/components';
//
import sidebarConfig from './SidebarConfig';
import {useSubscription} from '../../features/subscriptions/subscriptions/services/subscription.gql';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
    onToggleCollapse: PropTypes.func,
    collapseClick: PropTypes.bool
};

function IconCollapse({onToggleCollapse, collapseClick}) {
    return (
        <Tooltip title="Mini Menu">
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    alignItems: 'center',
                    color: 'text.primary',
                    justifyContent: 'center',
                    border: 'solid 1px currentColor',
                    ...(collapseClick && {
                        borderWidth: 2
                    })
                }}
            >
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: 'currentColor',
                        transition: (theme) => theme.transitions.create('all'),
                        ...(collapseClick && {
                            width: 0,
                            height: 0
                        })
                    }}
                />
            </CardActionArea>
        </Tooltip>
    );
}

// ----------------------------------------------------------------------

HomeSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function HomeSidebar({isOpenSidebar, onCloseSidebar}) {
    const theme = useTheme();
    const {pathname} = useLocation();
    const {subscription} = useSubscription();

    const {
        isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave
    } = useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center'
                    })
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box component={RouterLink} to='/' sx={{display: 'inline-flex'}}>
                        <Logo sx={{width: 60, height: 60}}/>
                    </Box>

                    <MHidden width="lgDown">
                        {!isCollapse &&
                        <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick}/>}
                    </MHidden>
                </Stack>

                {isCollapse ? (
                        <Avatar>
                            <AccountCircle/>
                        </Avatar>
                    ) :
                    (
                        <Link underline="none" component={RouterLink} to="#">
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: (theme) => theme.spacing(2, 2.5),
                                borderRadius: (theme) => theme.shape.borderRadiusSm,
                                backgroundColor: (theme) => theme.palette.grey[200]
                            }}>
                                {/*<Avatar src={account.photoURL} alt="photoURL"/>*/}
                                <Avatar>
                                    <AccountCircle/>
                                </Avatar>
                                <Box sx={{ml: 2}}>
                                    <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                                        {subscription?.customerObj?.name ?? ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Link>
                    )
                }
            </Stack>

            <NavSection navConfig={sidebarConfig} isShow={!isCollapse}/>

            <Box sx={{flexGrow: 1}}/>

            {!isCollapse && (
                <Box sx={{px: 2.5, pb: 3, mt: 10}}>
                    <Stack
                        alignItems='center'
                        spacing={3}
                        sx={{
                            p: 2.5,
                            pt: 5,
                            borderRadius: 2,
                            position: 'relative',
                            bgcolor: 'grey.200'
                        }}
                    >

                        <Box sx={{textAlign: 'center'}}>
                            <Typography gutterBottom variant="h6">
                                BCI Mali SA
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                La banque à votre rythme
                            </Typography>
                        </Box>

                        <Link
                            href='https://www.bci-banque.com/banque/mali/fr/'
                            target="_blank"
                            variant="contained"
                        >
                            Notre Site
                        </Link>
                    </Stack>
                </Box>
            )}
        </Scrollbar>
    );

    return (
        <Box sx={{
            [theme.breakpoints.up('lg')]: {
                flexShrink: 0,
                transition: theme.transitions.create('width', {
                    duration: theme.transitions.duration.complex
                })
            },
            width: {
                lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
            },
            ...(collapseClick && {
                position: 'absolute'
            })
        }}>
            <MHidden width='lgUp'>
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {width: DRAWER_WIDTH}
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width='lgDown'>
                <Drawer
                    open
                    variant='persistent'
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                            })
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </Box>
    );
}


