import React from 'react';
import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {Box, Card, CardHeader, CircularProgress, MenuItem, Select} from '@mui/material';
//
import {BaseOptionChart} from '../../../../../shared/components/charts';
import {fCFA} from "../../../../../utils/formatNumber";
import {useAccountStore} from "../../services/account.store";
import {useSearchStatLastOperations} from "../../services/account.services";

// ----------------------------------------------------------------------

const limits = [3, 5, 10, 15];

export default function AccountOperationChartWidget() {

    const {selectedAccount} = useAccountStore();

    const [limit, setLimit] = React.useState(10);

    const {statLastOperations, isLoading: loading} = useSearchStatLastOperations({
        account: selectedAccount?.id, month: limit
    });

    const labels = statLastOperations?.map(({label}) => label) ?? [];
    const debits = statLastOperations?.map(({debit}) => debit) ?? [];
    const credits = statLastOperations?.map(({credit}) => credit) ?? [];


    const series = [
        {
            name: 'Debit',
            type: 'area',
            data: debits,
        },
        {
            name: 'Credit',
            type: 'area',
            data: credits,
        }
    ];

    const chartOptions = merge(BaseOptionChart(), {
        labels,
        stroke: {width: [2, 2]},
        xaxis: {type: 'datetime'},
        fill: {type: ['gradient', 'gradient']},
        plotOptions: {bar: {columnWidth: '30%', borderRadius: 4}},
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => fCFA(y ?? 0)
            }
        }
    });

    return (
        <Card sx={{
            minHeight: 500,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CardHeader
                title={'Mes operations'}
                subheader={`${limit} derniers mois`}
                action={(
                    <Select value={limit} onChange={e => setLimit(e.target.value)}>
                        {limits.map(_limit => (
                            <MenuItem key={_limit} value={_limit}>{_limit}</MenuItem>
                        ))}
                    </Select>
                )}
            />

            {loading && (
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress/>
                </Box>
            )}

            {!loading && (
                <Box sx={{p: 3, pb: 1}} dir="ltr">
                    <ReactApexChart type="line" series={series} options={chartOptions} height={364}/>
                </Box>
            )}
        </Card>
    );
}
