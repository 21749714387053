
const index = {
    httpBaseUrl: '/kunpan-web-api',
    graphQlBaseUrl: '/kunpan-web-api/graphql',
    keycloakConfig: {
        realm: 'bci',
        clientId: 'bci-kunpan-web-client',
        url: '/auth',
    }
}

export default index;
