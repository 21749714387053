import React, {useEffect} from 'react';

import {Container} from '@mui/material';

import {AccountDashboard} from '../index';
import {useSubscription} from '../../../subscriptions/subscriptions/services/subscription.gql';
import {Page} from '../../../../shared/pages';
import {useAccountStore} from '../services/account.store';

export default function AccountsDashboardPage() {

    const {accounts} = useSubscription();
    const {selectedAccount, setSelectedAccount} = useAccountStore();

    useEffect(() => {
        if (selectedAccount) return;
        if (accounts && accounts.length > 0) {
            setSelectedAccount(accounts[0], 0);
        }
    }, [accounts, selectedAccount, setSelectedAccount]);

    return (
        <Page title='KUNPAN - Mes comptes'>
            <Container maxWidth='xl'>

                {selectedAccount && (
                    <AccountDashboard account={selectedAccount}/>
                )}

            </Container>
        </Page>
    );
}

