import create from 'zustand';


const setSelectedAccount = set => (selectedAccount, selectedAccountIndex) => {
    set((state) => ({...state, selectedAccount, selectedAccountIndex}));
}

const setAccountSearchPeriod = set => (accountId, period) => {
    set((state) => {
        const {accountSearchPeriod} = state;
        return ({...state, accountSearchPeriod: {...accountSearchPeriod, [accountId]: period}});
    });
}

export const useAccountStore = create(set => ({
    selectedAccount: null,
    selectedAccountIndex: null,
    accountSearchPeriod: {},

    ///
    setSelectedAccount: setSelectedAccount(set),
    setAccountSearchPeriod: setAccountSearchPeriod(set),
}));
