import Keycloak from 'keycloak-js';
import appConfig from '../configs';
import {createContext} from 'react';

const _kc = new Keycloak(appConfig.keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
        // onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        // pkceMethod: 'S256',
    })
        .then((authenticated) => {
            if (authenticated) {
                onAuthenticatedCallback(getSubject());
            } else {
                doLogin();
            }
        });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getRoles = () => _kc.tokenParsed?.realm_access?.roles;

const getName = () => {
    const tokenParsed = _kc.idTokenParsed;
    return tokenParsed?.given_name
        ? tokenParsed?.given_name
        : tokenParsed?.preferred_username
            ? tokenParsed?.preferred_username
            : tokenParsed?.email
                ? tokenParsed?.email
                : tokenParsed?.name;
}

const getEmail = () => _kc.idTokenParsed?.email ?? '';

const getFullName = () => _kc.idTokenParsed?.name ?? '';

const getSubject = () => _kc.idTokenParsed?.sub ?? '';

const AuthContext = createContext(null);

const auth = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    getRoles,
    getName,
    getEmail,
    getFullName,
    getSubject,
    AuthContext
};

export default auth;

