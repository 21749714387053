import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat
} from '@apollo/client';
import auth from './auth';
import appConfig from '../configs';

const authMiddleware = new ApolloLink((operation, forward) => {
    // if (auth.isLoggedIn()) {
    //     const cb = () => {
    //         config.headers.Authorization = `Bearer ${auth.getToken()}`;
    //         return Promise.resolve(config);
    //     };
    //     return auth.updateToken(cb);
    // }
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': `Bearer ${auth.getToken()}`,
        }
    }));

    return forward(operation);
})

const httpLink = new HttpLink({ uri: appConfig.graphQlBaseUrl });

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});

export default client;
