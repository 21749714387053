import {Icon} from '@iconify/react';
import React, {useRef, useState} from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {Button, Box, Divider, MenuItem, Typography, Avatar, IconButton} from '@mui/material';
import {AccountCircle} from "@mui/icons-material";
// components
import {MenuPopover} from '../../shared/components';
// services
import {auth} from '../../shared/services';
import {useSnackbar} from 'notistack';
import useIsMountedRef from '../../shared/hooks/useIsMountedRef';
import useAuth from '../../shared/hooks/useAuth';
import {useSubscription} from '../../features/subscriptions/subscriptions/services/subscription.gql';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Accueil',
        icon: homeFill,
        linkTo: '/'
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    const { logout } = useAuth();
    const { subscription } = useSubscription();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Impossible de se connecter', { variant: 'error' });
        }
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                {/*<Avatar src={account.photoURL} alt="photoURL"/>*/}
                <Avatar>
                    <AccountCircle/>
                </Avatar>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{width: 220}}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle1" noWrap>
                        {subscription?.customerObj?.name}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {subscription?.emailToNotify ?? subscription?.phoneToNotify}
                    </Typography>
                </Box>

                <Divider sx={{my: 1}}/>

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{typography: 'body2', py: 1, px: 2.5}}
                    >
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{p: 2, pt: 1.5}}>
                    <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                    {/*<Button fullWidth color="inherit" variant="outlined" onClick={auth.doLogout}>*/}
                        Se deconnecter
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
