import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateDDMMYYY(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

const _formatYYYYMMDDHHmmss = 'yyyy-MM-dd HH:mm:ss';
const _formatYYYYMMDDHHmmssSimple = 'yyyyMMddHHmmss';
export const dateToYYYYMMDDHHmmss = (date) => _format(_formatYYYYMMDDHHmmss)(date);
export const dateToYYYYMMDDHHmmssSimple = (date) => _format(_formatYYYYMMDDHHmmssSimple)(date);

const _formatYYYYMMDD = 'yyyy-MM-dd';
export const dateToYYYYMMDD = (date) => _format(_formatYYYYMMDD)(date);

const _formatHHmmss = 'yyyy-MM-dd HH:mm:ss';
export const dateToHHmmss = (date) => _format(_formatHHmmss)(date);

const _format = f => date => {
  if(!date) return '';
  return format(date, f);
}

export function formatDate(date, pattern) {
  if (!date) {
    date = new Date();
  }
  if (!pattern) {
    pattern = "lll";
  }
  moment.locale("fr");
  return moment(date).format(pattern);
}
