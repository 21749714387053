import axios from 'axios';
import auth from './auth';
import appConfig from '../configs';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (auth.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${auth.getToken()}`;
        return Promise.resolve(config);
      };
      return auth.updateToken(cb);
    }
  });
};

const fullUrl = (operation) => `${appConfig.httpBaseUrl}/${operation}`;

const getAxiosClient = () => _axios;

const get = (operation) => _axios.get(fullUrl(operation));
const post = (operation, data) => _axios.post(fullUrl(operation), data);
const put = (operation, data) => _axios.put(fullUrl(operation), data);
const _delete = (operation) => _axios.delete(fullUrl(operation));

const http = {
  HttpMethods,
  configure,
  getAxiosClient,
  get,
  post,
  put,
  delete: _delete
};

export default http;
