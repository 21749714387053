import React from 'react';
import {alpha} from '@mui/material/styles';
import {Box, Card, Typography} from '@mui/material';
import {
    AccountBalanceWallet,
    AccountBalanceWalletOutlined,
    CreditCard,
    CompareArrows
} from '@mui/icons-material';
import {fCFA} from "../../../../../utils/formatNumber";
import {useAccountStore} from "../../services/account.store";

const HEIGHT = 276;


export function AccountBalanceAccountingWidget() {

    const {selectedAccount} = useAccountStore();

    return (
        <AccountInfoWidget
            label='Solde Comptable'
            value={(selectedAccount && fCFA(selectedAccount.accountingBalance)) ?? 'xxx xxx F CFA'}
            icon={<AccountBalanceWalletOutlined/>}
            mi
            paletteColor='info'
        />
    );
}

export function ValuePositionWidget() {
    const {selectedAccount} = useAccountStore();

    return (
        <AccountInfoWidget
            mi
            label='Valeur Position'
            value={(selectedAccount && fCFA(selectedAccount.valuePosition)) ?? 'xxx xxx F CFA'}
            icon={<CompareArrows/>}
            paletteColor='error'
        />
    );
}

export function AccountInfoWidget({label, value, icon, mi = false, paletteColor}) {
    return (
        <Card sx={{
            height: {
                md: HEIGHT * .5 - 10,
                xs: HEIGHT,
            },
            boxShadow: 'none',
            textAlign: 'center',
            padding: (theme) => theme.spacing(5, 0),
            color: (theme) => theme.palette[paletteColor].darker,
            backgroundColor: (theme) => theme.palette[paletteColor].lighter
        }}>
            <Box sx={{
                margin: 'auto',
                display: {
                    md: 'none',
                    xs: 'flex',
                },
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                width: (theme) => theme.spacing(8),
                height: (theme) => theme.spacing(8),
                marginBottom: (theme) => theme.spacing(3),
                color: (theme) => theme.palette[paletteColor].dark,
                backgroundImage: (theme) => `linear-gradient(135deg, 
              ${alpha(theme.palette[paletteColor].dark, 0)} 0%, 
              ${alpha(theme.palette[paletteColor].dark, 0.24)} 100%)`
            }}>
                {icon}
            </Box>

            <Typography variant="h3">{value}</Typography>
            <Typography variant="subtitle2" sx={{opacity: 0.72}}>
                {label}
            </Typography>
        </Card>
    );
}
