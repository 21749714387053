import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
import React from 'react';
// material
import {useTheme, styled} from '@mui/material/styles';
import {Box, Card, CardHeader, CircularProgress, MenuItem, Select} from '@mui/material';
// utils
import {fCFA} from '../../../../../utils/formatNumber';
//
import {BaseOptionChart} from '../../../../../shared/components/charts';
import {useAccountStore} from "../../services/account.store";
import {useSearchStatLastOperations} from "../../services/account.services";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({theme}) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(4),
    '& .apexcharts-canvas svg': {height: CHART_HEIGHT},
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible'
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
    }
}));

// ----------------------------------------------------------------------

const limits = [3, 5, 10, 15];

export default function AccountOperationsPieWidget() {
    const theme = useTheme();

    const {selectedAccount} = useAccountStore();

    const [limit, setLimit] = React.useState(10);

    const {statLastOperations, isLoading: loading} = useSearchStatLastOperations({
        account: selectedAccount?.id, month: limit
    });

    const debit = statLastOperations?.map(line => line.debit)?.reduce((x, y) => x + y, 0) ?? 0;
    const credit = statLastOperations?.map(line => line.credit)?.reduce((x, y) => x + y, 0) ?? 0;

    const series = [debit, credit];

    const chartOptions = merge(BaseOptionChart(), {
        colors: [
            theme.palette.error.main,
            theme.palette.success.main,
            theme.palette.info.main,
            theme.palette.warning.main,
        ],
        labels: ['Debit', 'Credit'],
        stroke: {colors: [theme.palette.background.paper]},
        legend: {floating: true, horizontalAlign: 'center'},
        dataLabels: {enabled: true, dropShadow: {enabled: false}},
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesValue) => fCFA(seriesValue ?? 0),
                title: {
                    formatter: (seriesName) => `${seriesName}`
                }
            }
        },
        plotOptions: {
            pie: {donut: {labels: {show: false}}}
        }
    });

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CardHeader
                title={'Repartition operations'}
                subheader={`${limit} derniers mois`}
                action={(
                    <Select value={limit} onChange={e => setLimit(e.target.value)}>
                        {limits.map(_limit => (
                            <MenuItem key={_limit} value={_limit}>{_limit}</MenuItem>
                        ))}
                    </Select>
                )}
            />

            {loading && (
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress/>
                </Box>
            )}

            {!loading && (
                <ChartWrapperStyle dir="ltr">
                    <ReactApexChart type="pie" series={series} options={chartOptions} height={CHART_HEIGHT - 100}/>
                </ChartWrapperStyle>
            )}
        </Card>
    );
}
