import React from 'react';
import {Receipt, Home, Notifications, CallReceived} from '@mui/icons-material';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    subheader: "kunpan",
    items: [
      {
        title: 'acceuil',
        path: '/app/home',
        icon: <Home/>
      },
      {
        title: 'operations',
        path: '/app/account-operations',
        icon: <Receipt/>
      },
      {
        title: 'notifications',
        path: '/app/notifications',
        icon: <Notifications/>
      },
      // {
      //   title: 'beneficiaires',
      //   path: '/app/subscription-beneficiaries',
      //   icon: <CallReceived/>
      // },
    ],
  },

];

export default sidebarConfig;
