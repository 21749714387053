import React from 'react';

import ThemeConfig from './theme';
import AppRouter from './AppRouter';
import useAuth from './shared/hooks/useAuth';
import GlobalStyles from './theme/globalStyles';
import LoadingScreen, {ProgressBarStyle} from './shared/components/LoadingScreen';
import {ScrollToTop, BaseOptionChartStyle, NotistackProvider} from './shared/components';
import ThemeLocalization from './shared/components/ThemeLocalization';
import ThemePrimaryColor from "./shared/components/ThemePrimaryColor";

export default function App() {
    const {isInitialized, isAuthenticated} = useAuth();

    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <ThemeLocalization>
                    <NotistackProvider>
                        <GlobalStyles/>
                        <ProgressBarStyle/>
                        <BaseOptionChartStyle/>
                        <ScrollToTop/>
                        {!isInitialized ? <LoadingScreen/> : (
                            // isAuthenticated ? <AppRouter/> : <AppRouter/>
                            isAuthenticated ? <AppRouter/> : <div>Auth KO</div>
                        )}
                    </NotistackProvider>
                </ThemeLocalization>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}
