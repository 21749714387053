
import {useLocation, Outlet} from 'react-router-dom';
// material

// ----------------------------------------------------------------------

export default function MainLayout() {
    const {pathname} = useLocation();
    const isHome = pathname === '/';

    return (
        <>
            <Outlet/>
        </>
    );
}

// import React, {useState} from 'react';
// // material
// import {styled} from '@mui/material/styles';
// //
// import {HomeNavbar, HomeSidebar} from '.';
//
// // ----------------------------------------------------------------------
//
// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
//
// const RootStyle = styled('div')({
//     display: 'flex',
//     minHeight: '100%',
//     overflow: 'hidden'
// });
//
// const MainStyle = styled('div')(({theme}) => ({
//     flexGrow: 1,
//     overflow: 'auto',
//     minHeight: '100%',
//     paddingTop: APP_BAR_MOBILE + 24,
//     paddingBottom: theme.spacing(10),
//     [theme.breakpoints.up('lg')]: {
//         paddingTop: APP_BAR_DESKTOP + 24,
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(2)
//     }
// }));
//
// // ----------------------------------------------------------------------
//
//
// function MainLayout({children}) {
//     const [open, setOpen] = useState(false);
//
//     return (
//         <RootStyle>
//             <HomeNavbar onOpenSidebar={() => setOpen(true)}/>
//             <HomeSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>
//             <MainStyle>
//                 {children}
//             </MainStyle>
//         </RootStyle>
//     );
// }
//
// export default MainLayout;
