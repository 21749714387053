import {gql, useQuery} from '@apollo/client';
import {parseEntity} from '../../../../utils/gql.utils';
import useAuth from '../../../../shared/hooks/useAuth';

const QUERY_GET_SUBSCRIPTION = gql`
    query SubscriptionByUserId($userId: String!) {
        subscriptionByUserId(userId: $userId) {
            error message entity {
                id user account status compte phoneToNotify emailToNotify
                customerObj {
                    id name client
                    accounts {
                        id compte availableBalance accountingBalance valuePosition
                        devise operationDate
                    }
                }
            }
        }
    }
`;

export const useSubscription = () => {
    const {subscription: authSubscription} = useAuth();

    const result = useQuery(QUERY_GET_SUBSCRIPTION, {variables: {userId: authSubscription?.user}});

    const {entity: subscription, ...others} = parseEntity(result, 'subscriptionByUserId');

    const customer = subscription?.customerObj ?? {};
    const accounts = customer?.accounts ?? [];

    // console.log({subscription, customer, accounts});
    return {...others, subscription, customer, accounts};
}
