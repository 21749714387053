import React from 'react';
// material
import {useTheme} from '@mui/material/styles'
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer
} from '@mui/material';

//
import {Label} from '../../../../../shared/components';

export default function AccountOperationTable({operations, sx}) {

    const theme = useTheme();

    return (
        <TableContainer sx={sx}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Reference</TableCell>
                        <TableCell>Sens</TableCell>
                        <TableCell>Montant</TableCell>
                        <TableCell>Libele</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {operations && operations.map((operation) => (
                        <TableRow key={operation.id}>
                            <TableCell>{operation.operationDate}</TableCell>
                            <TableCell>{operation.operationReference}</TableCell>
                            <TableCell>
                                <Label
                                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                    color={
                                        (operation.sens === 'D' ? 'error' : 'success')
                                    }
                                >
                                    {operation.sens === 'D' ? 'Debit' : 'Credit'}
                                </Label>
                            </TableCell>
                            <TableCell align='right'>{Math.abs(operation.amount)}</TableCell>
                            <TableCell>{operation.operationLabel}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

