import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {formatDistance} from 'date-fns';
import {Link as RouterLink} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import {Receipt} from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
    Link,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
// utils

import {AccountOperationTable} from './index';
import {useAccountStore} from "../../services/account.store";
import {useSearchLastOperations} from "../../services/account.services";

// ----------------------------------------------------------------------

AccountOperationItem.propTypes = {
    operation: PropTypes.object.isRequired
};

function AccountOperationItem({account, operation}) {
    const {amount, sens, operationLabel, operationDate} = operation;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>
            <Box
                sx={{width: 48, height: 48, borderRadius: 1.5}}
            >
                <Receipt/>
            </Box>
            <Box sx={{
                flexGrow: 1
            }}>
                <Link to="#" color="inherit" underline="hover" component={RouterLink}>
                    <Typography variant="subtitle2" noWrap>
                        {`${sens === 'D' ? 'Debit' : 'Credit'} de ${Math.abs(amount)} ${account.devise}`}
                    </Typography>
                </Link>
                <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                    {operationLabel}
                </Typography>
            </Box>
            <Typography variant="caption" sx={{pr: 3, flexShrink: 0, color: 'text.secondary'}}>
                {formatDistance(operationDate, new Date())}
            </Typography>
        </Box>
    );
}

const limits = [10, 20, 50, 100];

export default function LastAccountOperationsWidgets() {

    const {selectedAccount} = useAccountStore();

    const [limit, setLimit] = React.useState(20);

    const {accountOperations, isLoading: loading} = useSearchLastOperations({
        account: selectedAccount?.id, limit
    });

    return (
        <Card sx={{
            maxHeight: 600,
            minHeight: 500,
            display: 'flex',
            flexDirection: 'column',
        }}>

            <CardHeader
                sx={{mb: 3}}
                title={`Mes ${limit} dernieres operations`}
                action={(
                    <Select value={limit} onChange={e => setLimit(e.target.value)}>
                        {limits.map(_limit => (
                            <MenuItem key={_limit} value={_limit}>{_limit}</MenuItem>
                        ))}
                    </Select>
                )}
            />

            <Divider/>

            {
                loading && (
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress/>
                    </Box>
                )
            }

            {!loading && (
                <AccountOperationTable
                    operations={accountOperations}
                    sx={{minWidth: 700, maxHeight: 700, overflow: 'scroll'}}
                />
            )}

            <Divider/>

            <Box sx={{p: 2, textAlign: 'right'}}>
                <Button
                    size='small'
                    color='inherit'
                    to='/app/account-operations'
                    component={RouterLink}
                    endIcon={<Icon icon={arrowIosForwardFill}/>}
                >
                    Recherche
                </Button>
            </Box>

        </Card>
    );
}
