import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import {alpha, useTheme, styled} from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Typography} from '@mui/material';
// components
import {MHidden} from '../../shared/components';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from '../../features/notifications/notifications/components/NotificationsPopover';
import useCollapseDrawer from "../../shared/hooks/useCollapseDrawer";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

HomeNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function HomeNavbar({onOpenSidebar}) {
    const { isCollapse } = useCollapseDrawer();

    return (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
                })
            }}
        >
            <ToolbarStyle>
                <MHidden width='lgUp'>
                    <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'text.primary'}}>
                        <Icon icon={menu2Fill}/>
                    </IconButton>
                    <Typography variant="h4" color='black'>BCI KUNPAN</Typography>
                </MHidden>

                <MHidden width='lgDown'>
                    <Typography variant="h4" color='black'>Bienvenue sur BCI KUNPAN</Typography>
                </MHidden>

                <Box sx={{flexGrow: 1}}/>

                <Stack direction='row' alignItems='center' spacing={{xs: 0.5, sm: 1.5}}>
                    {/*<NotificationsPopover/>*/}
                    <AccountPopover/>
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
