import {useQuery} from 'react-query';
import {useApiHttp} from '../../../../shared/hooks/useHttp';

import http from '../../../../shared/services/http';
import {dateToYYYYMMDD} from "../../../../utils/formatTime";


const URL = 'accounts';
const URL_OPS = 'account-operations';

const url = (operation) => `${URL}/${operation}`;


export const useSearchAccountBySubscriptionId = (subscriptionId) => {
    const {get} = useApiHttp();
    const ops = 'by-subscription';
    const {data, ...others} = useQuery(
        [URL, ops, subscriptionId],
        () => get(`${URL}/${ops}/${subscriptionId}`)
    );

    return {...others, accounts: data}
}

export const useSearchLastOperations = ({account, limit}) => {
    const {getData} = useApiHttp();
    const ops = 'last-operations';
    const {data, ...others} = useQuery(
        [URL_OPS, ops, account, limit],
        async () => getData(`${URL_OPS}/${ops}`, {params: {account, limit}})
    );

    return {...others, accountOperations: data};
}

export const useSearchStatLastOperations = ({account, month = 10}) => {
    const {getData} = useApiHttp();
    const ops = 'stat-last-operations';
    const {data, ...others} = useQuery(
        [URL_OPS, ops, account, month],
        () => getData(`${URL_OPS}/${ops}`, {params: {account, month}})
    );

    return {...others, statLastOperations: data};
}

export const useSearchAccountOperations = (search) => {
    const {getData} = useApiHttp();
    const ops = 'search';
    const {data: accountOperations, ...others} = useQuery(
        [URL_OPS, ops, search],
        () => getData(`${URL_OPS}/${ops}`, search)
    );

    return {...others, accountOperations}
}

export const useStatements = ({account, start, end}) => {
    const {getData} = useApiHttp();
    const ops = 'statements';
    let endpoint = `${URL}/${account}/${ops}`;
    let statementsKeys = [account];

    const queryParams = Object.entries({start, end})
        .filter(([, value]) => value)
        .map(([key, value]) => {
            statementsKeys.push(value);
            return `${key}=${dateToYYYYMMDD(value)}`
        }).join('&');

    if (queryParams && queryParams.length > 0) {
        endpoint = `${endpoint}?${queryParams}`;
    }

    const {data: statements, ...others} = useQuery(
        [URL_OPS, ops, statementsKeys],
        () => getData(endpoint)
    );

    return {...others, statements}
}


const findBySubscriptionId = (subscriptionId) => http.get(url(`by-subscription/${subscriptionId}`));

const lastOperations = (accountId, count = 20) => http.get(url(`${accountId}/last-operations/${count}`));

const statLastOperations = (accountId, count = 10) => http.get(url(`${accountId}/stat-last-operations/${count}`));

const searchAccountOperations = (search) => http.post(url('operations'), search);

export const accountServices = {
    findBySubscriptionId,
    lastOperations,
    statLastOperations,
    searchAccountOperations,
}
