import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCFA(amount, currency = 'XOF') {
  return Intl.NumberFormat('fr-ML', { style: 'currency', currency }).format(amount);
}

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '00' : '0.0,00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fNumberSpace(input = '', group = 4, separator= ' ') {
  input = `${input}`;

  if(group < 1) return input;

  const length = input.length;

  if (length <= group) return input;

  const nb = Math.ceil(length / group);

  const tokens = [];

  for (let i = 0; i < nb; i++) {
    tokens.push(input.substr(i * group, group));
  }

  return tokens.join(separator);
}

console.log(fNumberSpace('3837467263746983', 4));

