import React from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

import {LocalizationProvider} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';

import {ApolloProvider} from './shared/contexts/ApolloContext';
import {HttpProvider} from './shared/contexts/HttpContext';
import {AuthProvider} from './shared/contexts/AuthContext';
import {ReactQueryClientProvider} from './shared/contexts/ReactQueryContext';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {SettingsProvider} from './shared/contexts/SettingsContext';
import {CollapseDrawerProvider} from './shared/contexts/CollapseDrawerContext';


ReactDOM.render(
    <HelmetProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
            <SettingsProvider>
                <CollapseDrawerProvider>
                    <ReactQueryClientProvider>
                        <BrowserRouter>
                            <AuthProvider>
                                <HttpProvider>
                                    <ApolloProvider>
                                        <App/>
                                    </ApolloProvider>
                                </HttpProvider>
                            </AuthProvider>
                        </BrowserRouter>
                    </ReactQueryClientProvider>
                </CollapseDrawerProvider>
            </SettingsProvider>
        </LocalizationProvider>
    </HelmetProvider>,
    document.getElementById('root')
);
