import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import Keycloak from 'keycloak-js';
import appConfig from '../configs';

// ----------------------------------------------------------------------

let _kc = null;

const _axios = axios.create();


const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user, subscription} = action.payload;
        return {...state, isAuthenticated, isInitialized: true, user, subscription};
    },
    LOGIN: (state, action) => {
        const {user, subscription} = action.payload;
        return {...state, isAuthenticated: true, user, subscription};
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    method: 'oauth2',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    updateToken: (cb) => Promise.resolve(),
});

AuthProvider.propTypes = {
    children: PropTypes.node
};

const findSubscription = async (user) => {
    try {
        const subject = user.getSubject();
        const url = `${appConfig.httpBaseUrl}/api/v1/subscriptions/by-user/${subject}`;
        const headers = {Authorization: `Bearer ${user.getToken()}`}
        const {data: subscription} = await _axios.get(url, {headers});

        return subscription;
    } catch (e) {
        console.error(e);
        return null;
    }
}

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    console.log('######@@@@@!!! AuthProvider');

    useEffect(() => {
        console.log('######@@@@@!!! AuthProvider.useEffect');
        const initialize = async () => {
            console.log('######@@@@@!!! AuthProvider.useEffect.initialize');
            try {
                _kc = new Keycloak(appConfig.keycloakConfig);

                await _kc.init({
                    onLoad: 'login-required',
                    // onLoad: 'check-sso',
                    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                    // pkceMethod: 'S256',
                });

                const isAuthenticated = _kc.authenticated;

                console.log(`###@@@ Keycloak: `, {isAuthenticated});

                if (isAuthenticated) {

                    const user = toUser(_kc);

                    const subscription = await findSubscription(user);

                    console.log("=======> ", {subscription, user});

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {isAuthenticated, user, subscription}
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {isAuthenticated, user: null, subscription: null}
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {isAuthenticated: false, user: null, subscription: null}
                });
            }
        };

        initialize().catch(console.error);
    }, []);

    async function login() {
        await _kc.login();

        const isAuthenticated = _kc.authenticated;

        if (isAuthenticated) {
            const user = toUser(_kc);

            const subscription = await findSubscription(user);

            dispatch({type: 'LOGIN', payload: {user, subscription}});
        }
    }

    const updateToken = (minValidity) => _kc.updateToken(minValidity);

    const logout = () => {
        _kc.logout();
        dispatch({type: 'LOGOUT'});
    };

    const resetPassword = () => {
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'oauth2',
                login,
                logout,
                updateToken,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

const toUser = (_kc) => {
    return {
        getToken: function () {
            return _kc.token;
        },
        getUsername: function () {
            return _kc.tokenParsed?.preferred_username;
        },
        hasRole: function (roles) {
            const _roles = _kc.tokenParsed?.realm_access?.roles ?? [];
            if (typeof roles === 'string') return _roles.includes(roles);
            if (Array.isArray(roles)) return roles.some(role => _roles.includes(role));
            return false;
        },
        getRoles: function () {
            return _kc.tokenParsed?.realm_access?.roles ?? [];
        },
        getName: function () {
            const tokenParsed = _kc.idTokenParsed;
            return tokenParsed?.given_name
                ? tokenParsed?.given_name
                : tokenParsed?.preferred_username
                    ? tokenParsed?.preferred_username
                    : tokenParsed?.email
                        ? tokenParsed?.email
                        : tokenParsed?.name;
        },
        getEmail: () => _kc.idTokenParsed?.email ?? '',
        getFullName: () => _kc.idTokenParsed?.name ?? '',
        getSubject: () => _kc.idTokenParsed?.sub ?? '',
    }
}

export {AuthContext, AuthProvider};

