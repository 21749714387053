import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
    ApolloProvider as Provider
} from '@apollo/client';

import appConfig from '../configs';
import useAuth from '../hooks/useAuth';

const httpLink = new HttpLink({uri: appConfig.graphQlBaseUrl});

const authMiddleware = (auth) => new ApolloLink((operation, forward) => {
    if (!auth.isAuthenticated) return forward(operation);

    try {
        // await auth.updateToken(5);
        const token = auth.user.getToken();
        operation.setContext(({headers = {}}) => {
            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                }
            }
        });
        return forward(operation);
    } catch (e) {
        // auth.login();
    }
})

const client = (auth) => new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware(auth), httpLink),
});


export const ApolloProvider = ({children}) => {
    const auth = useAuth();

    return (
        <Provider client={client(auth)}>
            {children}
        </Provider>
    )
}
